<template>
  <div id="app">
      <home-page />
  </div>
</template>

<script>

import HomePage from './components/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage,
  }
}
</script>

<style>
  body {
    margin: 0;
    font-family: 'Montserrat-Regular', sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    color: #131313;
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    letter-spacing: 6.8px;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
