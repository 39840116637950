<template>
  <div class="home-page">
    <section class="logo-section">
        <img src="../assets/logo.svg" />
    </section>

    <section class="mission-section">
        <h3 class="mission-title">OUR MISSION</h3>

        <div class="mission-content">
          <p >Our mission is to enable the long term care community to participate more fully in clinical research and take charge of improving their care.</p>
        </div>
    </section>

    <section class="info-section">
        <div class="details first">Older adults carry 60% of the national disease burden but represent only 32% of patients in phase II and III clinical trials.
          <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2837461" target="_blank" rel="noopener noreferrer">See more details</a>
        </div>

        <div class="details second">
            We aim to change this, by connecting our network of long term care patients with clinical trials in meaningful, impactful medical research.
        </div>
    </section>
    <home-page-info @contact="showContactUs" />

    <contact-us-form ref="contact"/>

    <news-section />
  </div>
</template>

<script>
import HomePageInfo from '../components/HomePageInfo';
import ContactUsForm from '../components/ContactUsForm';
import NewsSection from '../components/NewsSection';
import '../assets/logo.svg';

export default {
  name: 'HomePage',

  components: {
    HomePageInfo,
    NewsSection,
    ContactUsForm,
  },

  computed: {
  },

  methods: {
    showContactUs() {
      if (this.$refs?.contact) {
        this.$refs.contact.$el.scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.home-page {
  margin: 0 auto;
  position: relative;

  .logo-section {
    background-image: linear-gradient(.85turn, #FFFFFF 60%, #E9FFFE);
    padding: 125px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    height: 200px;
  }

  .mission-section {
    background-image: linear-gradient(100deg, #4384FF33 0%, #24CAFF33 100%);
    padding: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .mission-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
  }

  .mission-content {
    max-width: 550px;
    font-size: 20px;
    line-height: 32px;
  }

  .info-section {
    padding: 140px 48px;
    background-color: #FFFFFF;
    display: grid;
    grid-template: auto 1fr/auto 1fr;
    grid-column-gap: 90px;
    grid-template-columns: 1fr 1fr;
  }

  .details {
    padding: 36px 45px;
    background: #FBFBFB 0% 0% no-repeat padding-box;
    border: 1px solid #C3C3C3;
    border-radius: 32px;
    text-align: center;
    font-size: 24px;
    line-height: 41px;
    letter-spacing: 0px;
  }

  @media (max-width: 749px) {
    .logo-section {
      padding: 95px 16px;
    }

    .logo {
      height: 150px;
    }

    .mission-section {
      padding: 83px 16px;
    }

    .mission-title {
      font-size: 24pt;
      letter-spacing: 4.08;
      padding-bottom: 16px;
    }

    .info-section {
      display: flex;
      flex-direction: column;
      padding: 48px 32px 0;


      .details {
        margin-bottom: 45px;
      }
    }
  }
}
</style>
