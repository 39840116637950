<template>
  <section class="home-page-info">
      <div class="info">
        <div class="image-wrapper">
          <img src="../../../ltc-sites/public/sponsors.svg" />
        </div>

        <div class="details-wrapper">
          <div class="details">
            <h3>Sponsors/ CRO's</h3>

            <p class="content">LTC Sites is your gateway to results when it comes to research in the long-term care population. Be connected with large numbers of trial participants quickly with one point of contact.</p>

            <button class="button" @click="$emit('contact')">Contact us</button>
          </div>
        </div>
      </div>

      <div class="info from-right">
        <div class="details-wrapper">
          <div class="details">
            <h3>Network</h3>

            <p class="content">Join our network and provide an important service to your patients by giving them the power to affect outcomes for the long-term care patient population. Set your organizations apart in their communities by showing that they are a center of excellent, forward thinking medical care. Compensation for hosting trials will be provided.</p>

            <button class="button" @click="$emit('contact')">Join us</button>
          </div>
        </div>

         <div class="image-wrapper">
            <img src="../../../ltc-sites/public/network.svg" />
          </div>
      </div>

      <div class="info">
       <div class="image-wrapper">
          <img src="../../../ltc-sites/public/patients.svg" />
        </div>

        <div class="details-wrapper">
          <div class="details">
            <h3>Patients</h3>

            <p class="content">Participate in the future of medical care and improve outcomes. The elderly population has not been included in medical research to the degree needed. Be part of the change and bring medical breakthroughs to the world.</p>

            <button class="button last-button" @click="$emit('contact')">Contact us</button>
          </div>
        </div>
      </div>
  </section>
</template>

<script>

export default {
  name: 'HomePageInfo',

  data() {
    return {
    };
  },
}
</script>

<style lang="scss" scoped>
.home-page-info {
  background-color: #FFFFFF;
}

.info {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-image: linear-gradient(.85turn, #FFFFFF 85%, #E9FFFE);
  grid-auto-rows: 818px;

  h3 {
    letter-spacing: 5.44px;
    font-size: 32px;
    line-height: 39px;
    font-weight: 400;
  }

  .details-wrapper {
    padding-bottom: 65px;
    display: flex;
    align-items: center;
    max-width: 550px;
    padding: 0 50px;
  }

  .content {
    font-size: 24px;
    line-height: 40px;
  }

  .details {
    text-align: left;
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      max-height: 620px;
      position: relative;
      top: 0;
      left: -100px;
    }
  }

  &.from-right {
    background-image: linear-gradient(.15turn, #FFFFFF 85%, #E9FFFE);

    .image-wrapper {
      justify-content: flex-start;

      img {
        left: 100px;
      }
    }

    .details {
      padding-left: 0;
      padding-right: 24px;
      font-weight: 400;
    }

    .details-wrapper {
      display: flex;
      justify-self: flex-end;
    }
  }

  @media (min-width: 1920px) {
    .image-wrapper {
      img {
        left: -350px;
      }
    }

    &.from-right {
      .image-wrapper {
        img {
          left: 350px;
        }
      }
    }
  }

  @media (max-width: 749px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: none;

    &.from-right {
      display: flex;
      flex-direction: column-reverse;
      background-image: none;

      .image-wrapper {
        img {
          left: 80px;
        }
      }
    }

    .details-wrapper {
      padding: 0 16px;
      font-size: 16px;
    }

    .last-button {
        margin-bottom: 56px;
    }

    .image-wrapper {
      width: 100%;
      padding-top: 80px;
      padding-bottom: 60px;

      img {
        left: 0;
        max-height: 300px;
        left: -80px;
      }
    }
  }
}
</style>
