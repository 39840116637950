<template>
  <div class="news-section">
    <h3 class="header">NEWS</h3>

    <div class="news-blurb" v-for="newsBlurb in newsBlurbs" :key="newsBlurb.link">
      <div class="title">{{ newsBlurb.title }}</div>

      <p>{{ newsBlurb.description }}</p>

      <button class="outline-button" @click="openLink(newsBlurb.link)">Read more</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsSection',

  computed: {
    newsBlurbs() {
      return [
        {
          title: 'Eli Lilly Launches COVID-19 Clinical Trial in Nursing Homes with Symphony as Partner',
          description: 'Pharmaceutical giant Eli Lilly and Company (NYSE: LLY) will soon begin a phase-three clinical trial of a potential coronavirus treatment in the long-term care setting, with nursing home operator Symphony Care Network as a partner.',
          link: 'https://skillednursingnews.com/2020/08/eli-lilly-launches-covid-19-clinical-trial-in-nursing-homes-with-symphony-as-partner/',
        },
        {
          title: 'University researchers monitor effects of COVID-19 vaccines in nursing home residents',
          description: 'A University researcher is leading a national effort to track vaccine adverse events in nursing home residents who have received the COVID-19 vaccine. Vaccine adverse events are health issues that arise following inoculation with a vaccine; these events may be caused by the vaccine itself or be completely coincidental, according to the Centers for Disease Control and Prevention. The research is funded through a National Institute on Aging grant awarded to the University last November.',
          link: 'https://www.browndailyherald.com/2021/03/15/university-researchers-monitor-effects-covid-19-vaccines-nursing-home-residents/',
        }
      ];
    }
  },

  methods: {
    openLink(link) {
      window.open(link, '_blank', 'noopener,noreferrer');
    },
  },
};

</script>

<style lang="scss" scoped>
.news-section {
  padding: 178px 100px;
  margin: 0 auto;
  text-align: left;
  background-color: #FFFFFF;

  .header {
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 48px;
    line-height: 49px;
  }

  .news-blurb {
    margin-bottom: 24px;

    .title {
      font-weight: bold;
      font-size: 21px;
      line-height: 25px;
    }

    p {
      font-size: 18px;
      line-height: 23px;
    }
  }

  @media (max-width: 749px) {
    padding: 64px 16px;

    .header {
      padding-bottom: 24px;
    }
  }
}
</style>
