<template>
  <div class="contact-us-form">
    <h3 class="contact-us-header">CONTACT US</h3>

    <form class="form-wrapper" ref="form" v-if="!success">
      <div class="input-row name">
        <md-field :class="['first-name-input', { 'md-invalid': submitted && !firstName }]">
          <label>First name</label>
          <md-input v-model="firstName" type="text"></md-input>
          <span class="md-error" v-if="submitted && !firstName">First name is required</span>
        </md-field>

        <md-field :class="['last-name-input', { 'md-invalid': submitted && !lastName }]">
          <label>Last name</label>
          <md-input v-model="lastName" type="text"></md-input>
          <span class="md-error" v-if="submitted && !lastName">Last name is required</span>
        </md-field>
      </div>

      <div class="input-row">
        <md-field :class="['email-input', { 'md-invalid': submitted && (!email || !validEmail) }]">
          <label>Email</label>
          <md-input v-model="email" type="email"></md-input>
          <span class="md-error" v-if="submitted && !email">Email is required</span>
          <span class="md-error" v-else-if="submitted && !validEmail">Email is invalid</span>
        </md-field>
      </div>

      <div class="input-row message">
        <md-field :class="[{ 'md-invalid md-error': submitted && !message }]">
          <label>Message</label>
          <md-textarea v-model="message"></md-textarea>
          <span class="md-error" v-if="submitted && !message">Message is required</span>
        </md-field>
      </div>

      <div class="input-row captcha">
        <vue-recaptcha
            :size="size"
            class="recaptcha"
            :sitekey="siteKey"
            ref="recaptcha"
            @verify="verifyRecaptcha"
            @expired="resetRecaptcha"
          />
        <span class="error" v-if="submitted && !recaptcha">Recaptcha is required</span>
      </div>

       <button class="button submit-button" type="submit" @click.stop.prevent="sendEmail">Submit</button>
    </form>

    <div v-else>
      <p class="thank-you">
        Thank you for reaching out. <br/>
        Your message has been sent successfully!
      </p>

      <button class="outline-button" @click="resetFields">Send another message</button>
    </div>
  </div>
</template>

<script>

import VueRecaptcha from 'vue-recaptcha';
import emailjs from 'emailjs-com';
import { EMAIL_SERVICE_ID, EMAIL_USER_ID, EMAIL_TEMPLATE_ID, CAPTCHA_VALUE } from './contact-us.const';

export default {
  name: 'ContactUsForm',

  components: {
    VueRecaptcha,
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      submitted: false,
      recaptcha: '',
      success: false,
      size: '100',
    };
  },

  mounted() {
    emailjs.init(this.emailUserId);
    this.resetFields();
  },

  computed: {
    siteKey() {
      return atob(CAPTCHA_VALUE);
    },

    emailServiceId() {
      return atob(EMAIL_SERVICE_ID);
    },

    emailTemplateId() {
      return atob(EMAIL_TEMPLATE_ID);
    },

    emailUserId() {
      return atob(EMAIL_USER_ID);
    },

    validEmail() {
      const regex = /^[^\s@]+@[^\s@]+$/;
      return regex.test(this.email);
    },

    isValid() {
      return Boolean(this.firstName && this.lastName && this.message && this.validEmail && this.recaptcha);
    },
  },

  methods: {
    resetFields() {
      this.submitted = false;
      this.success = false;
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.message = '';
      this.recaptcha = '';
    },

    resetRecaptcha() {
      this.recaptcha = '';
      this.$refs.recaptcha.reset();
    },

    verifyRecaptcha(val) {
      this.recaptcha = val;
    },

    async sendEmail() {
      const { firstName, lastName, email, message } = this;

      if (this.isValid) {
        try {
          await emailjs.send(this.emailServiceId, this.emailTemplateId, {
              from_name: `${firstName} ${lastName} <${email}>`,
              first_name: firstName,
              last_name: lastName,
              email: email,
              message: message,
              reply_to: email,
              'g-recaptcha-response': this.recaptcha,
          });

          this.success = true;
        } catch (error) {
          this.success = false;
          console.log(error);
        }
      } else {
        this.submitted = true;
      }
    }
  },
};

</script>

<style lang="scss" scoped>
.contact-us-form {
  background-color: #FBFBFB;
  padding: 178px 0;

  .contact-us-header {
    font-size: 40px;
    line-height: 49px;
    letter-spacing: 6.8px;
    padding-bottom: 48px;
  }

  .form-wrapper {
    max-width: 664px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 16px;
  }

  .input-row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 26px;

    &.name {
      margin-bottom: 6px;
    }
  }

  .first-name-input {
    margin-right: 10px;
    flex: 1;
  }

  .last-name-input {
    margin-left: 10px;
    flex: 1;
  }

  .thank-you {
    font-size: 20px;
    color: #131313;
    line-height: 32px;
    margin-top: 0;
  }

  .submit-button {
    align-self: flex-start;
    margin-top: 48px;
  }

  .outline-button {
    margin-top: 16px;
  }

  .captcha {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .recaptcha {
    cursor: pointer;
  }

  .error {
    color: #ff1744;
  }

  @media (max-width: 749px) {
      padding: 56px 0;

      .input-row {
        flex-direction: column;

        &.name {
          margin-bottom: 26px;
        }
      }

      .first-name-input {
        margin: 0;
        margin-bottom: 26px;
      }

      .last-name-input {
        margin: 0;
        width: 100%;
      }
    }
}
</style>
